// Não se esqueça de recompilar os scripts para poder ver as alterações feitas aqui!
//
// Você pode fazer isso pelo terminal de duas maneiras:
//
// npm run dev
// *Irá recompilar uma única vez todos os estilos e scripts do projeto*
//
// npm run watch-poll
// *Irá recompilar todos os estilos e scripts do projeto a primeira vez, e depois continuará rodando de fundo, e cada vez que você alterar um arquivo CSS ou JS e salvar,
//  ele irá compilar apenas o que você alterou!*

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

(function ($) {
    $(document).ready(function () {
        $("#cssmenu2 li.has-sub>a").on("click", function () {
            $(this).removeAttr("href");
            var element = $(this).parent("li");
            if (element.hasClass("open")) {
                element.removeClass("open");
                element.find("li").removeClass("open");
                element.find("ul").slideUp();
            } else {
                element.addClass("open");
                element.children("ul").slideDown();
                element.siblings("li").children("ul").slideUp();
                element.siblings("li").removeClass("open");
                element.siblings("li").find("li").removeClass("open");
                element.siblings("li").find("ul").slideUp();
            }
        });

        $("#cssmenu2>ul>li.has-sub>a").append('<span class="holder"></span>');

        function rgbToHsl(r, g, b) {
            (r /= 255), (g /= 255), (b /= 255);
            var max = Math.max(r, g, b),
                min = Math.min(r, g, b);
            var h,
                s,
                l = (max + min) / 2;

            if (max == min) {
                h = s = 0;
            } else {
                var d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0);
                        break;
                    case g:
                        h = (b - r) / d + 2;
                        break;
                    case b:
                        h = (r - g) / d + 4;
                        break;
                }
                h /= 6;
            }
            return l;
        }
    });

    $(document).on("click", 'a[href^="#"]', function (event) {
        event.preventDefault();

        $("html, body").animate(
            {
                scrollTop: $($.attr(this, "href")).offset().top,
            },
            500
        );
    });

    // Mobile Navigation
    if ($(".nav-menu").length) {
        var $mobile_nav = $(".nav-menu").clone().prop({
            class: "mobile-nav d-lg-none",
        });
        $("body").append($mobile_nav);
        $("body").prepend(
            '<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>'
        );
        $("body").append('<div class="mobile-nav-overly"></div>');

        $(document).on("click", ".mobile-nav-toggle", function (e) {
            $("body").toggleClass("mobile-nav-active");
            $(".mobile-nav-toggle i").toggleClass(
                "icofont-navigation-menu icofont-close"
            );
            $(".mobile-nav-overly").toggle();
        });

        $(document).on("click", ".mobile-nav .drop-down > a", function (e) {
            e.preventDefault();
            $(this).next().slideToggle(300);
            $(this).parent().toggleClass("active");
        });

        $(document).click(function (e) {
            var container = $(".mobile-nav, .mobile-nav-toggle");
            if (
                !container.is(e.target) &&
                container.has(e.target).length === 0
            ) {
                if ($("body").hasClass("mobile-nav-active")) {
                    $("body").removeClass("mobile-nav-active");
                    $(".mobile-nav-toggle i").toggleClass(
                        "icofont-navigation-menu icofont-close"
                    );
                    $(".mobile-nav-overly").fadeOut();
                }
            }
        });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
        $(".mobile-nav, .mobile-nav-toggle").hide();
    }

    // Navigation active state on scroll
    var nav_sections = $("section");
    var main_nav = $(".nav-menu, #mobile-nav");

    $(window).on("scroll", function () {
        var cur_pos = $(this).scrollTop() + 200;

        nav_sections.each(function () {
            var top = $(this).offset().top,
                bottom = top + $(this).outerHeight();

            if (cur_pos >= top && cur_pos <= bottom) {
                if (cur_pos <= bottom) {
                    main_nav.find("li").removeClass("active");
                }
                main_nav
                    .find('a[href="#' + $(this).attr("id") + '"]')
                    .parent("li")
                    .addClass("active");
            }
            if (cur_pos < 300) {
                $(".nav-menu ul:first li:first").addClass("active");
            }
        });
    });

    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $("#header").addClass("header-scrolled");
        } else {
            $("#header").removeClass("header-scrolled");
        }
    });

    if ($(window).scrollTop() > 100) {
        $("#header").addClass("header-scrolled");
    }

    // $(window).scroll(function () {
    //     if ($(this).scrollTop() < 650) {
    //         $("#imagemg").addClass("imagem-scrolled1");
    //         $("#imagemg").removeClass("imagem-scrolled2");
    //     } else {
    //         $("#imagemg").removeClass("imagem-scrolled1");
    //         $("#imagemg").addClass("imagem-scrolled2");

    //     }
    // });
    // $(document).ready(function () {
    //     if ($(window).scrollTop() < 650) {
    //         $("#imagemg").addClass("imagem-scrolled1");
    //         $("#imagemg").removeClass("imagem-scrolled2");
    //     } else {
    //         $("#imagemg").removeClass("imagem-scrolled1");
    //         $("#imagemg").addClass("imagem-scrolled2");

    //     }
    // });
    function aos_init() {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false,
        });
    }
    $(window).on("load", function () {
        aos_init();
    });

    // jQuery counterUp
    $('[data-toggle="counter-up"]').slideDown({
        delay: 10,
        time: 1000,
    });

    //adiciona menu fixed no scroll
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 150) {
            $(".fix-header .fix-men").addClass("fixed-menu");
            $(".fix-header .fix-men").removeClass("bg-invi");
        } else {
            $(".fix-header .fix-men").removeClass("fixed-menu");
            $(".fix-header .fix-men").addClass("bg-invi");
        }
    });
})(jQuery);

// $("#ajax_form").submit(function () {
//     event.preventDefault();
//     var formulario = $(this);
//     formulario.hide();
//     $("#loading").show();
//     var destination = $(this).attr("action");
//     $.ajax({
//         type: "POST",
//         url: destination,
//         data: $(this).serialize(),
//         dataType: "json",
//         success: function (response) {
//             alert(
//                 "Obrigado! Recebemos seu e-mail, entraremos em contato em breve!"
//             );
//             formulario.get(0).reset();
//             formulario.show();
//             $("#loading").hide();
//         },
//         error: function (response) {
//             alert(
//                 "Ocorreu um erro ao enviar seu e-mail. Por favor, tente novamente."
//             );
//             formulario.show();
//             $("#loading").hide();
//             console.log(response);
//         },
//     });
// });

$(document).ready(function(){
    $('.owl-carousel').owlCarousel();
  });

$('#owl-blog').owlCarousel({
    loop: true,
    // autoplay: 6000,
    // autoplaySpeed: 2000,
    slideSpeed: 3000,
    paginationSpeed: 6000,
    margin: 0,
    responsiveClass: true,
    nav: true,
    responsive: {
        0: {
            items: 1,
        },
        770: {
            items: 2,
        },
        990: {
            items: 3,
        },
        1500: {
            items: 4,
        }
    }

});

$('#owl-comentarios').owlCarousel({
    loop: true,
    autoplay: 4000,
    autoplaySpeed: 2000,
    slideSpeed: 3000,
    paginationSpeed: 6000,
    margin: 0,
    responsiveClass: true,
    nav: false,
    responsive: {
        0: {
            items: 1,
        },
        770: {
            items: 1,
        },
        990: {
            items: 2,
        },
        1500: {
            items: 3,
        }
    }

});
